<template>
    <v-container>
        <v-content class="pa-8">
            <v-card>
                <w-whiteboard></w-whiteboard>
            </v-card>
        </v-content>
    </v-container>
</template>

<script>
    export default {
        name: "WorkingTimes",
        data: () => ({
            title: "WorkingTimes"
        }),
    }
</script>

<style scoped>

</style>