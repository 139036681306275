import { render, staticRenderFns } from "./WorkTimes.vue?vue&type=template&id=79e728b9&scoped=true"
import script from "./WorkTimes.vue?vue&type=script&lang=js"
export * from "./WorkTimes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e728b9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
installComponents(component, {VCard,VContainer,VContent})
